export let CONFIG: typeof import('../../public/config/runtime.json')

export async function initialise(): Promise<void> {
  CONFIG = await (
    await fetch(`${getConfigPath()}?version=${window.__VERSION__}`, {
      headers: { 'Content-Type': 'application/json' },
    })
  ).json()
}

function getConfigPath(): string {
  if (import.meta.env.VITE_CONFIG_ENV) {
    return `/config/${import.meta.env.VITE_CONFIG_ENV}.json`
  }

  if (import.meta.env.DEV) {
    return `/config/local.json`
  }

  return `/config/runtime.json`
}
