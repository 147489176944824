import { ScopeContext } from '@sentry/types'
import {
  captureException,
  init,
  metrics,
  replayIntegration,
  setContext as setSentryContext,
  setUser as setSentryUser,
  thirdPartyErrorFilterIntegration,
} from '@sentry/vue'
import { App } from 'vue'

import { CONFIG } from '../../config'

export function setupErrorTracking(app: App<Element>): void {
  if (import.meta.env.DEV) return
  init({
    app,
    environment: CONFIG.SDK.SENTRY.ENV,
    release: window.__VERSION__,
    dsn: CONFIG.SDK.SENTRY.DSN,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.2,
    integrations: [
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: true,
        networkCaptureBodies: true,
        networkDetailAllowUrls: [/\/json\/.+/],
        // deny `/json/answer` as this could contain PII details if the users
        // responds with open text answers that are sensitive
        networkDetailDenyUrls: [/\/json\/answer/],
      }),
      thirdPartyErrorFilterIntegration({
        filterKeys: ['response-submission'],
        behaviour: 'drop-error-if-contains-third-party-frames',
      }),
    ],
    beforeSend: event => {
      if (window.location.hostname === 'localhost') return null
      return event
    },
  })
}

export function setUser({ id, segment }: { id: string; segment?: string }): void {
  setSentryUser({ id, segment })
}

interface InterviewContext {
  roundId: number | undefined
  currentProgress: number | undefined
  audienceType: string | undefined
}

type PromptContext =
  | Readonly<{ type: 'roundLanding'; round: RoundLandingContext }>
  | Readonly<{ type: 'question'; question: QuestionContext }>
  | Readonly<{ type: 'message'; message: MessageContext }>
  | Readonly<{ type: 'consent' }>
  | Readonly<{ type: 'preVideoTestCard' }>
  | Readonly<{ type: 'videoTest'; question: QuestionContext }>
  | Readonly<{ type: 'preVideoMessage' }>

interface QuestionContext {
  id: number
  type: string
  media:
    | {
        url: string
        type: 'audio' | 'video' | 'image'
      }
    | undefined
}

interface MessageContext {
  media:
    | {
        url: string
        type: 'image'
      }
    | undefined
}

interface RoundLandingContext {
  id: number
  progress: number
}

interface Contexts {
  interview: InterviewContext
  prompt: PromptContext
}

export function setContext<T extends keyof Contexts>(
  name: T,
  context: Contexts[T] | undefined,
): void {
  setSentryContext(name, context ?? null)
}

export function capture(error: Error, context?: Partial<ScopeContext>): void {
  setGlobalContext()
  captureException(error, context)
}

export { metrics as captureMetric }

export function setGlobalContext(): void {
  setSentryContext('global', {
    hostLocation: !window.parent
      ? undefined
      : window.location != window.parent.location
        ? document.referrer
        : document.location.href,
  })
}
