/* eslint-disable @typescript-eslint/no-explicit-any */
export function replaceTransformers<TransformerName extends string>(
  copy: string,
  transformers: {
    [T in TransformerName]?: (copy: string) => any
  },
): string | any[] {
  if (!/(\[%\w+\(.+?\)])/.test(copy)) return copy

  return copy.split(/(\[%\w+\(.+?\)])/).map(s => {
    const groups = s.match(/\[%(?<key>\w+)\((?<matchedCopy>.+?)\)]/)?.groups
    if (!groups) return s
    const { matchedCopy, key } = groups
    if (!matchedCopy) return s
    return transformers[key as TransformerName]?.(matchedCopy) ?? matchedCopy
  })
}
