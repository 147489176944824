import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  { path: '', redirect: '/marketeer/dashboard' },
  { path: 'dashboard', name: 'dashboard', component: () => import('./SurveyPage.vue') },
  { path: 'question/:questionId', component: () => import('./QuestionPage.vue'), props: true },
  {
    name: 'MARKETEER::QUESTION::RESPONSE',
    path: 'question/:questionId/response/:responseId',
    component: () => import('./QuestionPage.vue'),
    props: true,
  },
]

export default routes
