import { TranslationKey } from './__generated__'

export function transformLanguageToTranslationKey(
  language: string,
  country: string,
): TranslationKey {
  switch (language) {
    case 'ARA':
      return 'ar'
    case 'AFR':
      return 'af_ZA'
    case 'BUL':
      return 'bg'
    case 'ZHOt':
    case 'ZHOT':
      return 'zh_TW'
    case 'ZHOs':
    case 'ZHOS':
      return 'zh_Hans_HK'
    case 'CES':
      return 'cs'
    case 'DAN':
      return 'da'
    case 'DUT':
      return 'nl'
    case 'ENG':
      return 'en_GB'
    case 'FIN':
      return 'fi'
    case 'FRE':
      return 'fr'
    case 'GER':
      return 'de'
    case 'GRE':
      return 'el'
    case 'HIN':
      return 'hi'
    case 'HUN':
      return 'hu'
    case 'IND':
      return 'id'
    case 'ITA':
      return 'it'
    case 'JPN':
      return 'ja'
    case 'KOR':
      return 'ko'
    case 'LIT':
      return 'lt'
    case 'NOR':
      return 'nb'
    case 'POL':
      return 'pl'
    case 'POR':
      if (country === 'BR') {
        return 'pt_BR'
      }
      return 'pt_PT'
    case 'RON':
      return 'ro'
    case 'RUS':
      return 'ru'
    case 'SRP':
      return 'sr'
    case 'SLK':
      return 'sk'
    case 'SLV':
      return 'sl'
    case 'SPA':
      if (country === 'EC') {
        return 'es_EC'
      }
      if (country === 'PE') {
        return 'es_PE'
      }
      return 'es'
    case 'SWE':
      return 'sv'
    case 'TGL':
      return 'tl'
    case 'THA':
      return 'th'
    case 'TUR':
      return 'tr'
    case 'UKR':
      return 'uk'
    case 'URD':
      return 'ur'
    case 'VIE':
      return 'vi'
  }

  return 'en_GB'
}
