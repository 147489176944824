import { TranslationKey, TranslationKeyToCopyMeta } from './__generated__'
import en_GB from './__generated__/en_GB.json'
import { getCopyString } from './get-copy-string'
import { replacePlaceholders } from './replace-placeholders'
import { replaceTransformers } from './replace-transformers'

export * from './settings'
export * from './transformer'

export type { TranslationKeyToCopyMeta, TranslationKey }

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type TranslationKeyToTranslation = Record<keyof TranslationKeyToCopyMeta, any>

let copy: TranslationKeyToTranslation = en_GB
let locale: TranslationKey = 'en_GB'

export async function setLocale(newLocale: TranslationKey): Promise<void> {
  locale = newLocale
  copy = (await import(`./__generated__/${locale}.json`)).default
}

export function c<TranslationKey extends keyof TranslationKeyToCopyMeta>(
  key: TranslationKey,
  {
    quantity = 1,
    placeholders = {},
    transformers = {},
  }: {
    quantity?: number
    placeholders?: {
      [PlaceholderKey in TranslationKeyToCopyMeta[TranslationKey]['placeholders'][number]]?: string
    }
    transformers?: {
      [TransformerKey in TranslationKeyToCopyMeta[TranslationKey]['transformers'][number]]?: (
        copy: string,
      ) => any
    }
  } = {},
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
): any {
  return replaceTransformers(
    replacePlaceholders(getCopyString(copy[key], quantity), placeholders),
    transformers,
  )
}

const RTL_LOCALES: TranslationKey[] = ['ar', 'ur']

export function isRtlLocale(): boolean {
  return RTL_LOCALES.includes(locale)
}
