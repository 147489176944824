import { RouteRecordRaw } from 'vue-router'

const isDev = import.meta.env.DEV

const routes: RouteRecordRaw[] = [
  {
    path: ':roundGuid',
    component: () => import('../interview/components/page/InterviewPage.vue'),
    props: true,
  },
  { path: '', component: () => import('./components/pages/PageNotFound.vue') },
  ...(isDev
    ? [
        {
          path: 'recorder',
          component: () =>
            import('../question-video/components/dumb/VideoRecorderWithControls.vue'),
        },
      ]
    : []),
]

export default routes
